import { Component } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { rem, Color, Font, responsive } from "../../utils/style";

// Components
import RitualButton from "../global/RitualButton";

// Styled Elements
const HeroContent = styled.div`
  text-align: center;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroTitle = styled.div.attrs({
  className: "mb-2 mb-md-4",
})`
  margin: 0 auto;

  h1 {
    ${Font.circular};
    margin: 0 auto;
    text-align: center;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    font-weight: 500;
    letter-spacing: -0.4px;

    ${responsive.sm`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
    `};

    ${responsive.lg`
      font-size: ${rem(66)};
      line-height: ${rem(72)};
      letter-spacing: -2px;
    `};
  }
`;

const HeroHeadline = styled.div.attrs({
  className: "mb-4 mb-md-5",
})`
  margin: 0px auto;
  width: 200px;

  ${responsive.sm`
    width: 340px;
    margin-bottom: 36px
  `};

  ${responsive.md`
    width: 320px;
    margin-bottom: 40px
  `};

  ${responsive.lg`
    width: 500px;
  `};

  p {
    ${Font.dutch};
    margin: 0;
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    letter-spacing: 0;

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `};
  }
`;

const HeroCta = styled.div``;

const HeroRedeem = styled.div`
  margin-top: 52px;
  ${responsive.sm`
    margin-top: 80px;
  `};

  ${responsive.md`
    margin-top: 52px;
  `};

  ${responsive.lg`
    margin-top: 123px;
  `};

  a {
    ${Font.circular}
    color: ${Color.ritualBlue};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    letter-spacing: 0;
    cursor: pointer;
    margin: 0;
    border-bottom: 2px solid ${Color.ritualBlue};
  }
`;

export default class GiftingHero extends Component {
  render() {
    let {
      title,
      headline,
      imageMobile,
      imageDesktop,
      backgroundColor,
      cta,
    } = this.props;

    const titleMarkdown = title.childMarkdownRemark.html.replace(
      intl.t("gifting.title-markdown-replace-gift", "Gift "),
      intl.t("gifting.title-markdown-replace-gift-with", "Gift<br /> "),
    );
    const headlineMarkdown = headline.childMarkdownRemark.html;

    return (
      <HeroContent>
        <HeroTitle dangerouslySetInnerHTML={{ __html: titleMarkdown }} />
        <HeroHeadline dangerouslySetInnerHTML={{ __html: headlineMarkdown }} />
        <HeroCta>
          <RitualButton className="alt-hover" {...cta}>
            {cta.label}
          </RitualButton>
        </HeroCta>
        {/* TODO: Add "Redeem Gift" for Gift Card v2
              <HeroRedeem>
                <MagicLink href="">Redeem Gift</MagicLink>
              </HeroRedeem>
              */}
      </HeroContent>
    );
  }
}
