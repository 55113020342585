import { Component } from "react";
import styled from "styled-components";
import Container from "../Container";
import Row from "../Row";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { rem, Font, media, Color, Opacity } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import VerticallyCentered from "../VerticallyCentered";
import RitualButton from "../global/RitualButton";
import { addDisclaimerLink, processHtml } from "../../utils/markdown";

const ArticleWrapper = styled(Container).attrs({
  className: "container pl-sm-0 pr-sm-0",
})`
  z-index: 1;
  position: relative;
  padding-bottom: 120px;

  ${media.tablet`
    padding-bottom: 80px;
    padding-left: 0px;
    padding-right: 0px;
  `};

  ${media.mobile`
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
  `};
`;

const ArticleRow = styled(Row).attrs({
  className: "row d-flex align-items-stretch justify-content-space-between",
})`
  position: relative;
`;

const ImageColumn = styled.div``;

const ContentColumn = styled.div.attrs({
  className: "d-flex",
})`
  ${media.mobile`
    text-align: ${(p) => p.mobileTextAlign};
  `};

  h1,
  h2,
  h3,
  h4 {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(40)};
    line-height: ${rem(54)};
    font-weight: 500;
    text-align: left;
    letter-spacing: -1.4px;
    margin-bottom: 24px;

    ${media.tablet`
      font-size: ${rem(24)};
      line-height: ${rem(34)};
      letter-spacing: -0.4px;
      margin-bottom: 16px;
    `};

    ${media.mobile`
      letter-spacing: -0.2px;
      margin-top: 32px;
      text-align: ${(p) => p.mobileTextAlign};
    `};
  }

  p {
    ${Font.dutch}
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    color: ${Color.ritualBlue};
    margin: 0;

    ${media.tablet`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `};

    ${media.mobile`
      text-align: ${(p) => p.mobileTextAlign};
    `};

    a {
      ${Font.dutch};
      border: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  a {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    font-weight: 500;
    text-align: left;
    letter-spacing: 0;
    cursor: pointer;
    margin: 0;
    border-bottom: 2px solid ${Color.ritualBlue};
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: ${Opacity.light};
    }

    ${media.tablet`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `};
  }

  ol,
  ul {
    position: relative;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    list-style-position: outside;
    margin-top: 32px;

    ${media.tablet`
        margin-top: 24px;
    `};

    li {
      position: relative;
      ${Font.dutch};
      color: ${Color.ritualBlue};
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      font-weight: normal;
      text-align: left;
      letter-spacing: 0;
      margin-bottom: 24px;

      ${media.tablet`
        font-size: ${rem(16)};
        line-height: ${rem(26)};
        margin-bottom: 16px;
      `};

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  ul {
    &.icon {
      li {
        i {
          width: ${rem(24)};
          height: ${rem(24)};
          position: relative;

          svg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  ol {
    counter-reset: ordered-list-counter;

    li {
      counter-increment: ordered-list-counter;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(ordered-list-counter) " ";
        ${Font.circular};
        color: ${Color.ritualBlue};
        font-size: ${rem(26)};
        line-height: ${rem(26)};
        font-weight: 500;
        text-align: left;
        letter-spacing: -0.3px;

        ${media.tablet`
          font-size: ${rem(22)};
          line-height: ${rem(22)};
          letter-spacing: -0.2px;
        `};
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (min-width: 450px) and (max-width: 749px) {
    height: 375px;

    img {
      /* vertical centering, Img breaks when container flex */
      top: calc((((100vw - 40px) - 375px) * -1) / 2) !important;
    }
  }
`;

const ArticleLinks = styled.div.attrs({
  className: "d-flex flex-column",
})`
  margin-top: 32px;

  ${media.tablet`
      margin-top: 24px;
  `};

  a {
    width: fit-content;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.tablet`
      margin-bottom: 16px;
    `};

    ${media.desktop`
      &.-underline {
        font-size: ${rem(18)};
        line-height: ${rem(28)};
      }
    `};
  }
`;

const FeatureCta = styled.div`
  margin-top: 32px;
  a:hover {
    opacity: 1;
  }

  ${media.mobile`
    margin-top: 56px;
  `};
`;

export default class FeaturedArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wrapperHeight: null,
    };
    this.image = null;
    this.content = null;
  }

  renderList() {
    let { list, icons } = this.props;

    return (
      <ul className="icon">
        {list.map((label, i) => {
          let Icon = Icons[icons[i]];
          return (
            <li key={i} className="d-flex align-items-center">
              <i>
                <Icon />
              </i>
              <p className="ml-3">{label}</p>
            </li>
          );
        })}
      </ul>
    );
  }

  renderCta() {
    let { cta } = this.props;
    return (
      <FeatureCta>
        <RitualButton {...cta}>{cta.label}</RitualButton>
      </FeatureCta>
    );
  }

  render() {
    let {
      image,
      align,
      children,
      list,
      markdown,
      cta,
      centerMobile /* not yet implemented for lists and articles */,
      className,
    } = this.props;

    return (
      <ArticleWrapper className={className}>
        <ArticleRow>
          <ImageColumn
            className={
              align === "left"
                ? "col-12 col-sm-6 offset-sm-1 order-sm-2"
                : "col-12 col-sm-6 order-sm-1"
            }
            left={align === "left"}
          >
            <VerticallyCentered>
              <ImageWrapper>
                {image && (
                  <GatsbyImage
                    className="article-image"
                    image={getImage(image)}
                    loading="eager"
                    alt={image.description}
                    aria-label={image.description}
                    style={{
                      userSelect: "none",
                      userDrag: "none",
                      pointerEvents: "none",
                      touchCallout: "none",
                    }}
                  />
                )}
              </ImageWrapper>
            </VerticallyCentered>
          </ImageColumn>

          <ContentColumn
            mobileTextAlign={centerMobile ? "center" : "left"}
            className={
              align !== "left"
                ? "col-12 col-sm-5 offset-sm-1 order-sm-2"
                : "col-12 col-sm-5 order-sm-1"
            }
          >
            <VerticallyCentered>
              {markdown && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: addDisclaimerLink(processHtml(markdown)),
                  }}
                />
              )}
              {children && <ArticleLinks>{children}</ArticleLinks>}
              {list && this.renderList()}
              {cta && this.renderCta()}
            </VerticallyCentered>
          </ContentColumn>
        </ArticleRow>
      </ArticleWrapper>
    );
  }
}
